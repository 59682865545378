import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import ProfileForm from "../../components/ProfileForm/ProfileForm";
import { UserPageContainer } from '../LoginPage/style'
import { jwtDecode } from "jwt-decode";

const MyProfilePage = () => {
    const [userData, setUserData] = useState();

    useEffect(() => {
        var decodedToken = jwtDecode(window.localStorage.getItem("jwt__token"));
        const { company, email } = decodedToken;
        setUserData({company, email})
    }, [])

    return (
        <UserPageContainer>
            <ProfileForm userData={userData} />
            <Footer />
        </UserPageContainer>
    )
}

export default MyProfilePage; 