import React, { useEffect, useState } from "react";
import TextFieldComp from "../TextFieldComp/TextFieldComp"
import { AuthForm, PasswordPaper } from "./style"
import CustomButton from "../CustomButton/CustomButton"
import PasswordStrenghChecker from "../PasswordStrenghChecker/PasswordStrenghChecker";
import api from "../../api/api"
import Backdrop from "../Backdrop/Backdrop"
import { checkPasswordRequirements, getParamsFromURL } from "../../utils/methods"
import { FeedbackModal } from "../../modals/FeedbackModal/FeedbackModal";

const LoginForm = () => {
    const { token, id } = getParamsFromURL(window.location.search);
    const [loading, setLoading] = useState(false);
    const [isFeedbackModal, setIsFeedbackModal] = useState(null);
    const [strength, setStrength] = useState({});
    const [authForm, setAuthForm] = useState({
        password: '',
        confirm_password: '',
    });

    const checkValidToken = (_token, email) => {

        var formData = {
            "token": _token,
            "email": email
        }

        api.post('/Client/ValidateResetToken', formData)
            .then((response) => {
                return response.data
            })
            .then((data) => {
            })
            .catch((e) => {
                if (e.response?.data?.code === 'Token') {
                    setIsFeedbackModal(e.response?.data?.description)
                }
            })
    }

    useEffect(() => {
        checkValidToken(token, id)
    }, [])


    const handleCredentials = (e) => {
        setAuthForm(authForm => ({
            ...authForm,
            [e.target.name]: e.target.value
        }));
    }

    const handleAuthentication = (e) => {
        e.preventDefault()
        var logPasswordErrors = checkPasswordRequirements(strength);

        if (logPasswordErrors.length > 0) {
            window.alert(`A senha deve conter pelo menos:\n${logPasswordErrors.toString().replaceAll(",", ", ")}.`
            );
        }

        if (logPasswordErrors.length === 0) {
            if (authForm.confirm_password === authForm.password && authForm.password !== '' && authForm.confirm_password !== '') {
                var createPasswordURL = '/Authentication/ResetUserPassword'
                var formData = {
                    resetToken: token,
                    email: id,
                    password: authForm.password
                }

                setLoading(true);
                api.post(createPasswordURL, formData)
                    .then((response) => {
                        return response.data
                    })
                    .then((data) => {
                        setLoading(false)
                        window.localStorage.setItem('jwt__token', data);
                        window.location.href = '/';
                    })
                    .catch((e) => {
                        setLoading(false)
                        if (e.response?.data[0] === "Usuário não encontrado") {
                            setIsFeedbackModal("Usuário não encontrado")
                        } else if (e.response?.data[0] === "Invalid token.") {
                            setIsFeedbackModal("Token expirado")
                        } else {
                            setIsFeedbackModal(e.response?.data[0])
                        }
                    })
            } else {
                return false
            }
        }
    }

    return (
        <PasswordPaper elevation={2} >
            <p className="headline-small">Conclua seu cadastro</p>
            <AuthForm onSubmit={handleAuthentication}>
                <TextFieldComp
                    name={"password"}
                    label={"Crie uma senha"}
                    type={"password"}
                    value={authForm.password}
                    onChange={handleCredentials}
                    fullWidth={true}
                    placeholder="Digite sua senha"
                    helperText={authForm.password > 0 && authForm.password < 8 && "Senha muito curta"}
                    required
                />

                <PasswordStrenghChecker
                    strength={strength}
                    setStrength={setStrength}
                    password={authForm.password}
                />

                <TextFieldComp
                    error={authForm.confirm_password !== authForm.password}
                    name={"confirm_password"}
                    label={"Confirme sua senha"}
                    value={authForm.email}
                    onChange={handleCredentials}
                    type={"password"}
                    fullWidth={true}
                    placeholder="Digite a senha criada"
                    helperText={authForm.confirm_password !== authForm.password && "Senha acima não corresponde á senha criada."}
                    required
                />
                <CustomButton
                    type="submit"
                    variant="contained"
                    sx={{ width: '50%' }}>
                    Entrar
                </CustomButton>
            </AuthForm>
            {loading &&
                <Backdrop open={loading} />}

            <FeedbackModal
                open={isFeedbackModal !== null}
                type={"error"}
                feedbackText={isFeedbackModal}
                feedbackSubtext={"Entre em contato com Replica para solicitar um novo link de cadastro."}
                handleClose={() => { setIsFeedbackModal(null) }} />
        </PasswordPaper>
    )
}

export default LoginForm;