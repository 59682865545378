import React, { useState, useEffect } from "react";
import { Container, PageHeader } from "./style";
import { ArrowBack } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom"
import CustomButton from "../../components/CustomButton/CustomButton";
import api from "../../api/api";
import { checkToken } from "../../utils/methods";
import { Check, VerticalAlignBottom } from '@mui/icons-material';
import axios from "axios";
import PreparingFileModal from "../../modals/PreparingFileModal/PreparingFileModal"
import { downloadFileFromRequest } from "../../utils/methods";

const PreviewPage = () => {
    const [job, setJob] = useState(null);
    const { id } = useParams();
    const [capeData, setCapeData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [fileProgress, setFileProgress] = useState(0)
    const [fileError, setFileError] = useState(null)

    useEffect(() => {
        checkToken();
        getJobInfos()
        getCapeData();
    }, [])

    const handleApprove = () => {
        var allCompaniesURL = `/Job/${job.id}/UpdateJobStatus/`
        var formData = {
            "statusId": 5
        }

        api.put(allCompaniesURL, formData)
            .then((response) => {
                return response.data;
            }).then((data) => {
                if (data === 'Atualização feita com sucesso!') {
                    window.location.reload();
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    const handleDownload = () => {
        setIsLoading(true);
        axios
            .get(capeData.downloadLink, {
                responseType: 'blob',
                onDownloadProgress: progressEvent => {
                    let percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100)
                    setFileProgress(percentCompleted)
                }
            })
            .then((response) => {
                var filename = `${job.name}-${job.companyName.replace(" ", "")}-replica.zip`.toLowerCase();
                downloadFileFromRequest(response.data, false, filename)
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error downloading the file:', error);
                setFileError(error)
            });
    }

    const getCapeData = () => {
        axios.get("https://storage.bycape.io/account-221/fixed/DownloadsPreviews_Feed.json")
            .then(response => response.data)
            .then(data => {
                const campaign = data.filter((c) => c.replicaPortalId === id)
                if (campaign.length > 0) {
                    setCapeData(campaign[0])
                }
            })
            .catch(error => console.error('Error fetching JSON:', error));
    }

    const getJobInfos = () => {
        var jobWithBlobInfos = '/Job/GetJobWithBlobInfos/'
        var formData = {
            "jobId": Number(id)
        }

        api.post(jobWithBlobInfos, formData).then((response) => {
            return response.data;
        }).then((data) => {
            setJob(data)
        }).catch((error) => {
            if (error.status === 404) {
                console.log(error)
            } else {
                window.location.href = "/"
            }
        })
    }

    return (
        <Container>
            <PageHeader>
                <Link to={`/jobs/${id}`} className="title-small">
                    <ArrowBack /> Voltar
                </Link>

                <div className="cape-btns">
                    {job?.status === "Replicating" &&
                        <CustomButton endIcon={<Check />} variant="contained" onClick={handleApprove}>
                            <span className='title-small'> Aprovar job</span>
                        </CustomButton>}

                    {capeData?.downloadLink &&
                        <CustomButton endIcon={<VerticalAlignBottom />} variant="outlined" onClick={handleDownload}>
                            <span className='title-small'> Baixar peças </span>
                        </CustomButton>}
                </div>
            </PageHeader>

            <iframe style={{ border: "none" }}
                width="100%"
                height="100%"
                src={capeData?.previewLink} allowFullScreen>
            </iframe>

            {isLoading &&
                <PreparingFileModal
                    open={isLoading}
                    handleClose={() => { setIsLoading(false) }}
                    progress={fileProgress}
                    fileError={fileError}
                    label="Estamos preparando seu arquivo..."
                />}
        </Container>
    )
}

export default PreviewPage;