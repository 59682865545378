import styled, { css } from "styled-components";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

export const DialogModal = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 10px; 
        padding: 20px; 
        gap: 20px; 
        width: 30%;
        min-width: 350px;
        text-align: center;

        ${(props) => css`
            ${props.sx}  
        `}

        .success-container{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px; 
        }
    }
`