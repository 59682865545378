import React, { useState } from "react";
import { IconButton } from "@mui/material";
import CustomButton from "../../components/CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import { DialogModal, ModalTitle, ModalContent, ModalActions } from "../ApproveBudgetModal/style"
import api from "../../api/api";
import AssetUpload from "../../components/AssetUpload/AssetUpload";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import Backdrop from "../../components/Backdrop/Backdrop";
import { FeedbackModal } from "../FeedbackModal/FeedbackModal";

const UploadModal = ({ open, handleClose, job, selectedFiles, setSelectedFiles }) => {
    const [progress, setProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('INIT');
    const [isOpenFeebackModal, setIsOpenFeedbackModal] = useState(false)
    const [feedbackError, setFeedbackError] = useState(null)

    const handleSendFiles = () => {
        var submitAsset = '/BlobStorage/SubmitAsset'
        console.log(selectedFiles)
        var formData = new FormData();
        formData.append('CompanyId', "2")
        formData.append('JobId', job.id.toString())

        selectedFiles.forEach((file) => {
            formData.append('File', file)
        })

        setUploadStatus('LOADING');
        api.post(submitAsset, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (event) => {

                let progress = Math.round(
                    (event.loaded * 100) / event.total
                );
                setProgress(progress / 1.2);
            },
        }).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Upload de arquivo realizado com sucesso!") {
                setProgress(100);
                changeJobStatusToReview();
            }
        }).catch((error) => {
            if (error.response.data.errors[""][0] === "Failed to read the request form. Request body too large. The max request body size is 30000000 bytes.") {
                setUploadStatus('INIT');
                setFeedbackError("Seu arquivo excede o limite de 30MB por upload")
            }
            setIsOpenFeedbackModal(true);
        })
    }

    const changeJobStatusToReview = () => {
        var allCompaniesURL = `/Job/${job.id}/UpdateJobStatus/`
        var formData = {
            "statusId": 3
        }

        api.put(allCompaniesURL, formData)
            .then((response) => {
                return response.data;
            }).then((data) => {
                if (data === 'Atualização feita com sucesso!') {
                    setUploadStatus('DONE');
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
            }).catch((error) => {
            })
    }

    return (
        <DialogModal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                minWidth: '50%'
            }}
        >
            <ModalTitle className="title-large">
                Upload de Assets
            </ModalTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>
            <ModalContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {uploadStatus !== "INIT" ?
                    <>
                        <p className="headline-small">{progress < 100 ? 'Enviando seus materiais...' : 'Feito!'}</p>
                        <ProgressBar progress={progress} />
                    </> :
                    <AssetUpload
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        setProgress={setProgress}
                        setUploadStatus={setUploadStatus}
                        uploadStatus={uploadStatus}
                        job={job}
                        progress={progress}
                        modal={true}
                    />
                }
            </ModalContent>

            <Backdrop open={uploadStatus === "DONE"} />

            {uploadStatus === "INIT" &&
                <ModalActions>
                    <CustomButton sx={{ minWidth: '50%' }} onClick={handleSendFiles} variant={"contained"}>
                        <span className="title-small">
                            Enviar para análise
                        </span>
                    </CustomButton>
                </ModalActions>}

            <FeedbackModal
                open={isOpenFeebackModal}
                type={"error"}
                feedbackText={"Erro no Upload"}
                feedbackSubtext={feedbackError}
                handleClose={() => { window.location.reload() }} />

        </DialogModal>
    )
}

export default UploadModal;