import styled from "styled-components";
import { LoginPaper } from "../LoginForm/style";

export const RequestPassPaper = styled(LoginPaper)`
`
export const AuthForm = styled.form`
    width: 100%;   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 15px;
    text-align: left;

    p.title-small{
        font-weight: 400;
        margin: 0;
    }
`