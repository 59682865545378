import React, { useState } from "react";
import TextFieldComp from "../TextFieldComp/TextFieldComp"
import { AuthForm, RequestPassPaper } from "./style"
import CustomButton from "../CustomButton/CustomButton"
import Backdrop from "../Backdrop/Backdrop"
import api from "../../api/api"
import { FeedbackModal } from "../../modals/FeedbackModal/FeedbackModal"
import { useNavigate } from "react-router-dom";

const RequestResetPasswordForm = () => {
    const navigate = useNavigate()
    const [authForm, setAuthForm] = useState({
        email: '',
        name: 'Cliente',
    });
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openSendTokenFeedback, setOpenSendTokenFeedback] = useState(false);

    const handleCredentials = (e) => {
        setAuthForm(authForm => ({
            ...authForm,
            [e.target.name]: e.target.value
        }));
    }

    const handleResetPassword = (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        api.post('/Client/SendUserPasswordResetTokenEmail',
            {
                email: authForm.email,
                name: authForm.name
            }).then((res) => {
                if (res.status === 200) {
                    setOpenBackdrop(false);
                    setOpenSendTokenFeedback(true);

                    setTimeout(() => {
                        setOpenSendTokenFeedback(false);
                        navigate("/login");
                    }, 2000);
                }
            }).catch((err) => {
                console.error(err)
            })
    }

    return (
        <RequestPassPaper elevation={2} >
            <p className="headline-small">Redefinir Senha</p>
            <AuthForm onSubmit={handleResetPassword}>
                <p className="title-small">Digite seu e-mail e enviaremos um link para que você possa escolher uma nova senha.</p>
                <TextFieldComp
                    name={"email"}
                    label={"E-mail"}
                    value={authForm.email}
                    onChange={handleCredentials}
                    type={"e-mail"}
                    fullWidth={true}
                    placeholder="seu e-mail"
                    required
                />
                <CustomButton
                    type="submit"
                    variant="contained"
                    sx={{ width: '50%' }}>
                    Entrar
                </CustomButton>
            </AuthForm>

            <Backdrop open={openBackdrop} />

            <FeedbackModal
                open={openSendTokenFeedback}
                type={'success'}
                feedbackText={'Verifique seu e-mail!'}
                handleClose={() => {
                    setOpenSendTokenFeedback(false);
                }}
                feedbackSubtext={"Enviamos um novo link para redefinição de senha."} />
        </RequestPassPaper>
    )
}

export default RequestResetPasswordForm;