import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BudgetPage from './page/BudgetPage/BudgetPage';
import JobPage from './page/JobPage/JobPage';
import JobsListPage from './page/JobsListPage/JobsListPage';
import App from './App';
import {
  BudgetsListPage,
  CalculadoraPage,
  LoginPage,
  RegisterPage,
  RequestResetPassword,
  PreviewPage,
  MyProfilePage
} from "./page/pages"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/finish-register" element={<RegisterPage />} />
        {/* <Route path="/meu-perfil" element={<MyProfilePage />} /> */}
        <Route path="/forgot-password" element={<RequestResetPassword />} />
        <Route path="/calculadora" element={<CalculadoraPage />} />
        <Route path="/jobs" element={<JobsListPage />} />
        <Route path="/jobs/:id" element={<JobPage />} />
        <Route path="/jobs/:id/preview" element={<PreviewPage />} />
        <Route path="/budgets" element={<BudgetsListPage />} />
        <Route path="/budgets/:id" element={<BudgetPage />} />
        <Route path="/meu-perfil" element={<MyProfilePage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
