import React, {
  useEffect, useState
} from 'react';
import { ReactComponent as RIcon } from '../../icons/r-vector.svg';
import { Container, Links } from './style';
import { useLocation, useParams } from 'react-router-dom';
import MenuDrop from '../MenuDrop/MenuDrop';

export default function Navbar() {
  let location = useLocation();
  const [email, setEmail] = useState();

  useEffect(() => {
    if (window.localStorage.getItem('user__obj') !== "" &&
      window.localStorage.getItem('user__obj') !== null
    ) {
      setEmail(JSON.parse(window.localStorage.getItem('user__obj')).email);
    }
  }, [])

  var auth =
    location.pathname === '/' ||
      location.pathname === '/my-profile' ||
      location.pathname === '/login' ||
      location.pathname === '/forgot-password' ||
      location.pathname === '/reset-password' ||
      location.pathname === '/finish-register'
      ? false : true;

  return (
    <Container>
      <div className={"navbar-wrapper"}>
        <a href={auth? "/" : "https://www.byreplica.io/"} className={"icons"}>
          <RIcon />
        </a>

        {auth &&
          <Links>
            <a href="/calculadora" className={`title-small ${location.pathname.includes('calculadora') && 'active'}`}>CALCULADORA</a>
            <a href="/budgets" className={`title-small ${location.pathname.includes('budgets') && 'active'}`}>BUDGETS</a>
            <a href="/jobs" className={`title-small ${location.pathname.includes('jobs') && 'active'}`}>JOBS</a>
          </Links>}

        {auth &&
          <div className='user-mail'>
            <MenuDrop email={email} />
          </div>}
      </div>
    </Container>
  );
}
