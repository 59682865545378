import React from "react";
import { ContainerRow } from "./style";
import parse from 'html-react-parser';

const TermRow = ({ id, description, text }) => {
    return (
        <ContainerRow>
            <div className="title-term">
                <p className="label-medium">
                    {id + 1}. {description}
                </p>
            </div>
            <div className="body-medium terms-container">
                {parse(text)}
            </div>
        </ContainerRow>
    )
}

export default TermRow;