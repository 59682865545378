import React, {
    useState, useEffect
} from "react";
import { Container, PageHeader } from "./style";
import JobTable from "../../components/JobTable/JobTable";
import SearchBar from "../../components/SearchBar/SearchBar"
import api from "../../api/api";
import { checkToken } from "../../utils/methods";
import { CircularProgress } from "@mui/material";
import { StatusProvider } from "../../context/StatusContext";
import { jwtDecode } from "jwt-decode";

const JobsListPage = () => {
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([...jobs]);
    const [requestStatus, setRequestStatus] = useState("INITIAL")

    useEffect(() => {
        checkToken();

        var decodedToken = jwtDecode(window.localStorage.getItem("jwt__token"));
        const { company } = decodedToken;
        var allJobs = '/Job/GetJobsByCompany'
        var formData = { companyId: company }

        api.post(allJobs, formData).then((response) => {
            return response.data;
        }).then((data) => {
            setRequestStatus("LOADED")
            setJobs(data)
        }).catch((error) => {
            if (error.status === 404) {
                setRequestStatus("NOT FOUND")
                console.log(error)
            } else {
                window.location.href = "/"
            }
        })
    }, [])

    useEffect(() => {
        setFilteredJobs([...jobs])
    }, [jobs])

    const filterCompanyList = (e) => {
        const searchTerm = e.target.value.toLowerCase();

        const filteredList = jobs.filter((item) =>
            item.name.toLowerCase().includes(searchTerm))

        if (e.target.value === '' || e.target.value === undefined) {
            setFilteredJobs(jobs);
        } else {
            setFilteredJobs(filteredList)
        }
    }

    return (
        <StatusProvider>
            <Container>
                <PageHeader>
                    <div className="company-title">
                        <p className="headline-small"> <u>Meus Jobs</u> </p>
                    </div>
                    <SearchBar onChange={filterCompanyList} placeholder={"Pesquise um job..."} />
                </PageHeader>


                {requestStatus === "LOADED" && <JobTable filteredJobs={filteredJobs} jobs={jobs} setFilteredJobs={setFilteredJobs} />}
                {requestStatus === "NOT FOUND" && <p className="title-medium">Ah, ainda não existem jobs.</p>}
                {requestStatus === "INITIAL" && <div className="loading-container">
                    <CircularProgress color="inherit" />
                    <p className="title-medium">Carregando...</p>
                </div>}
            </Container>
        </StatusProvider>
    )
}

export default JobsListPage;