import React, { useState } from "react";
import { AssetRow } from "../JobBody/style";
import { IconButton } from '@mui/material';
import { VerticalAlignBottom } from '@mui/icons-material';
import DeleteAssetModal from '../../modals/DeleteAssetModal/DeleteAssetModal';
import api from "../../api/api";
import { downloadFileFromRequest } from "../../utils/methods";

const JobAssetRow = ({ job, blob }) => {
    const [openDeleteAsset, setOpenDeleteAsset] = useState(false);
    const handleDownloadBlob = async () => {
        var downloadBlobFile = '/BlobStorage/DownloadAsset/'
        var formData = {
            "blobInfoId": blob.id,
            "jobId": job.id,
            // "companyName": job.companyName,
            "companyId": "2"
        }
        try {
            const response = await api.post(downloadBlobFile, formData, {
                responseType: 'blob'
            });
            downloadFileFromRequest(response.data, blob)
        } catch (error) {
            console.error('Download failed', error);
        }
    }

    return (
        <AssetRow>
            <span className='label-large'>
                {blob.name}
            </span>
            <div className='action-row'>
                <IconButton onClick={handleDownloadBlob}>
                    <VerticalAlignBottom fontSize='small' />
                </IconButton>
            </div>
            <DeleteAssetModal open={openDeleteAsset} handleClose={() => { setOpenDeleteAsset(false) }} />
        </AssetRow>
    )
}
export default JobAssetRow;