import React, { useEffect, useState } from "react";
import { Fab, LinearProgress } from "@mui/material";
import { DialogModal } from "./style"
import { TaskAlt, ErrorOutline } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton/CustomButton";

const PreparingFileModal = ({ open, handleClose, progress, label, fileError }) => {
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        if (progress === 100) {
            setIsComplete(true);
        }
    }, [progress])

    useEffect(() => {
        if (isComplete) {
            setTimeout(() => {
                handleClose()
            }, 1500);
        }
    }, [isComplete])

    if (fileError) {
        return (
            <DialogModal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="success-container">
                    <Fab color="error" disableFocusRipple disableRipple disableTouchRipple size="small"
                        sx={{
                            boxShadow: 'none', cursor: 'initial', color: "#fff",
                            ":active": { boxShadow: "none" }, display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                        <ErrorOutline />
                    </Fab>
                    <p className="title-large">
                        {fileError}
                    </p>
                    <CustomButton variant="contained">
                        <span className="title-medium"
                            onClick={() => { window.location.reload() }}>Reiniciar página</span>
                    </CustomButton>
                </div>
            </DialogModal>)

    }

    if (isComplete) {
        return (
            <DialogModal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="success-container">
                    <Fab color="success" disableFocusRipple disableRipple disableTouchRipple size="small"
                        sx={{
                            boxShadow: 'none', cursor: 'initial', color: "#fff",
                            ":active": { boxShadow: "none" }, display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                        <TaskAlt />
                    </Fab>
                    <p className="title-large">
                        Concluído!
                    </p>
                </div>
            </DialogModal>)

    }

    if (fileError === null && isComplete === false)
        return (
            <DialogModal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <LinearProgress sx={{ height: 10, borderRadius: 2 }} color="success" variant="determinate" value={progress} />
                    <p className="title-medium">{label}</p>
                </>
            </DialogModal>
        )
}

export default PreparingFileModal;