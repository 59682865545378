import React, { useEffect, useState } from "react"
import { Fab, DialogTitle, Dialog, IconButton, DialogContent } from "@mui/material"
import { ErrorOutline, TaskAlt, Close } from "@mui/icons-material"

export const FeedbackModal = ({ open, type, feedbackText, handleClose, feedbackSubtext }) => {
    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', minWidth: '300px', width: '30%' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            {type === "success" && <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <Close />
            </IconButton>}

            <DialogTitle sx={{ padding: '20px 0', gap: '10px', display: 'flex', textAlign: 'center', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="title-large">
                {type === "success" && <Fab color="success" disableFocusRipple disableRipple disableTouchRipple size="small"
                    sx={{
                        boxShadow: 'none', cursor: 'initial', color: "#fff",
                        ":active": { boxShadow: "none" }, display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <TaskAlt />
                </Fab>}
                {type === "error" && <Fab color="error" disableFocusRipple disableRipple disableTouchRipple size="small"
                    sx={{
                        boxShadow: 'none', cursor: 'initial', color: "#fff",
                        ":active": { boxShadow: "none" }, display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <ErrorOutline />
                </Fab>}
                {feedbackText}

                <DialogContent sx={{ padding: 0 }}>
                    <p className="title-medium" style={{ margin: 0 }}>
                        {feedbackSubtext}
                    </p>
                </DialogContent>
            </DialogTitle>
        </Dialog>)
}

