import React, { useState } from "react";
import TextFieldComp from "../TextFieldComp/TextFieldComp"
import { AuthForm, ProfilePaper } from "./style"
import CustomButton from "../CustomButton/CustomButton";
import { FeedbackModal } from "../../modals/FeedbackModal/FeedbackModal";
import api from "../../api/api"
import { getCookie } from "../../utils/methods";

const ProfileForm = ({ userData }) => {
    const [openFeedback, setOpenFeedback] = useState(false)

    const handleResetToken = () => {
        api.post('/Client/SendUserPasswordResetTokenEmail', {
            email: userData?.email,
            name: getCookie('client__name')
        }).then((res) => {
            if (res.status === 200) {
                setOpenFeedback(true);
            }
        }).catch((err) => {
            console.error(err)
        })
    }

    return (
        <ProfilePaper elevation={2} >
            <p className="headline-small">Meu Perfil</p>
            <AuthForm>
                <TextFieldComp
                    name={"email"}
                    label={"E-mail"}
                    value={userData?.email}
                    onChange={() => { }}
                    type={"text"}
                    fullWidth={true}
                    placeholder="user@gmail.com"
                    readOnly />

                <CustomButton
                    fullWidth
                    onClick={handleResetToken}
                    variant="contained">
                    <span className="title-small">Redefinir minha senha</span>
                </CustomButton>

                <FeedbackModal
                    open={openFeedback}
                    type={'success'}
                    feedbackText={'Verifique seu e-mail!'}
                    handleClose={() => { setOpenFeedback(false) }}
                    feedbackSubtext={'Enviamos um link para criação da sua nova senha!'}
                />
            </AuthForm>
        </ProfilePaper>
    )
}

export default ProfileForm;