import React, { useState, useEffect } from "react";
import { Container, PageHeader } from "./style";
import { ArrowBack } from "@mui/icons-material";
import JobHeader from "../../components/JobHeader/JobHeader";
import { Link, useParams, useNavigate } from "react-router-dom"
import JobBody from "../../components/JobBody/JobBody";
import { Breadcrumbs, Skeleton } from "@mui/material";
import api from "../../api/api";
import { checkToken } from "../../utils/methods";
import CustomButton from "../../components/CustomButton/CustomButton";
import axios from "axios";

const JobPage = () => {
    const [job, setJob] = useState(null);
    const [isCapeDataAvailable, setIsCapeDataAvailable] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        checkToken();

        var jobWithBlobInfos = '/Job/GetJobWithBlobInfos/'
        var formData = {
            "jobId": Number(id)
        }

        api.post(jobWithBlobInfos, formData).then((response) => {
            return response.data;
        }).then((data) => {
            setJob(data);
        }).catch((error) => {
            if (error.status === 404) {
                console.log(error)
            } else {
                window.location.href = "/"
            }
        })
    }, [])
    useEffect(() => {
        if (job) {
            checkCapeData();
        }
    }, [job])

    const handleOpenPreview = () => {
        navigate("preview")
    }

    const checkCapeData = () => {
        axios.get("https://storage.bycape.io/account-221/fixed/DownloadsPreviews_Feed.json")
            .then(response => response.data)
            .then(data => {
                const campaign = data.filter((c) => c.replicaPortalId === id)
                if (campaign.length > 0) {
                    if (job.status === "Replicating" || job.status === "Delivered") {
                        setIsCapeDataAvailable(true)
                    }
                }
            })
            .catch(error => console.error('Error fetching JSON:', error));
    }

    return (
        <>
            {job ?
                <Container>
                    <PageHeader>
                        <Link to={"/jobs"} className="title-small">
                            <ArrowBack /> Voltar
                        </Link>
                        {isCapeDataAvailable &&
                            <CustomButton variant="contained" onClick={handleOpenPreview}>
                                <span className='title-small'> Acompanhe a produção</span>
                            </CustomButton>}
                    </PageHeader>
                    <JobHeader job={job} />
                    <JobBody job={job} />
                </Container> : <Container>
                    <PageHeader>
                        <Breadcrumbs>
                            <Link to='/jobs'>
                                Jobs
                            </Link>
                        </Breadcrumbs>
                        <Link to={"/jobs"} className="title-small">
                            <ArrowBack /> Voltar
                        </Link>
                    </PageHeader>
                    <Skeleton variant="rounded" width={"100%"} height={"10%"} />
                    <Skeleton variant="rounded" width={"100%"} height={"15%"} />
                </Container>

            }</>
    )
}

export default JobPage;